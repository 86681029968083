import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import Contact from "../components/organisms/contact";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";

import kraam from "../../static/design/img/2020/6917.jpg";
import sauzen from "../../static/design/img/2020/7040.jpg";
import nagerechten from "../../static/design/img/2020/8889.jpg";

import headerImage from "../../static/design/img/header/pages/ritorno-by-night.jpg";

const WaaromRitorno = () => (
  <Layout>
    <SEO
      title="Feest organiseren"
      description="Ritorno bakt voor u en uw genodigden pizza’s die enkel in de betere pizza-restaurants in Italië worden opgediend.
      Wij komen tot een dergelijk resultaat als gevolg van de combinatie van onze rechtstreeks uit Italië geïmporteerde
      authentieke houtovens en ons professioneel opgeleid, klantvriendelijk personeel."
    />

    <Nav />
    <Header heading="Feest organiseren" image={headerImage} darkBg={true} />

    <section
      className="pt-8 pb-7 text-center pattern-bg"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row no-dev">
          <div className="col-sm-8 mx-auto">
            <h2 className="title">Feest organiseren?</h2>
            <p>
              Ritorno bereidt voor u en uw genodigden pasta's die enkel in de
              betere pasta-restaurants in Italië worden opgediend. Wij komen tot
              een dergelijk resultaat als gevolg van de combinatie van onze
              verse ingrediënten en ons professioneel opgeleid, klantvriendelijk
              personeel.
            </p>
          </div>
          <div className="col-sm-12">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze Authentieke oven"
            />{" "}
          </div>
        </div>
      </div>
    </section>
    <section
      className="pt-2 pb-5 pattern-bg"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-10 mx-auto">
            <h3>Ons pastakraam</h3>
            <p>
              Bestaat uit een heel mooie buffettafel en een stijlvolle
              overkapping. Aan de buffettafel krijgen de pasta en de sauzen de
              laatste behandeling alvorens zij worden toebedeeld aan uw
              genodigden. Dit gebeurt snel: in de tafel zijn 6 mandjes ingewerkt
              waarin de verschillende pasta’s in kokend water worden
              ondergedompeld. In elk mandje kunnen 2 porties en de
              onderdompeltijd is 30 sec. Dit betekent dat wij elke minuut kunnen
              voorzien in een twintigtal porties! Verder aan de tafel, krijgen
              de gasten, uiteraard volgens eigen keuze, één van de zes
              verschillende sauzen toebedeeld.
            </p>
            <p>
              De pasta wordt geserveerd in mooie pastabekers en wordt gegeten
              met behulp van een vorkje. Verder aan de buffettafel kunnen uw
              genodigden de pasta nog garneren met Parmezaan, kerstomaatjes,
              rucola,… . Ieder komt zoveel keer als gewild aan het kraam. Het
              pastafeest is namelijk à volonté.
            </p>
            <p>
              Ons pastakraam laat zich quasi overal installeren. Het is
              demonteerbaar waardoor de ondergrond (bv los grint), een trap of
              een smallere doorgang, geen onoverbrugbare hindernis vormen.
              Geloof ons, met ons exclusief pastakraam haalden wij reeds de
              nodige lofbetuigingen binnen.
            </p>
            <div className="text-center">
              <img className="mb-2 mt-2" src={kraam} alt="Ons pastakraam" />
            </div>

            <h3>Onze pasta's</h3>
            <p>
              Wij voorzien in drie pastasoorten: Fusili, Rigatone en
              Pappardelle. De pasta krijgt één van de zes sauzen toebedeeld. De
              klassieke bolognaise saus maakt deel uit van ons assortiment, iets
              wat jongeren misschien wel kunnen appreciëren, maar … onze variant
              smaakt toch net iets anders dan de klassieke variant ervan.
              Daarnaast voorzien wij in echt kwaliteitsvolle sauzen die zonder
              twijfel de meest kritische eters onder ons zullen overtuigen. Ook
              werd er gedacht aan de vegetarische en viseters onder uw gasten.
              Denk hierbij aan een heerlijke vissaus met gerookte zalm,
              tomatensaus met gegrilde groenten,…
            </p>
            <div className="text-center">
              <img
                className="mb-2 mt-2"
                src={sauzen}
                alt="Onze verschillende sauzen"
              />
            </div>

            <h3>Onze voor- en nagerechten</h3>
            <p>
              Wenst u uw gasten ook een heerlijk voor –en nagerecht voor te
              schotelen, dan zorgen wij daar ook voor. Denk hierbij aan
              Italiaanse salades of aperitiefhapjes. Na de pasta is een
              ambachtelijk gemaakte tiramisu of en chocomousse misschien nog wel
              een gepaste afronder. De klant is koning en in overleg is veel
              mogelijk en … niets moet! Enkel uw welbevinden is onze motivatie.
            </p>
            <div className="text-center">
              <img
                className="mb-2 mt-2"
                src={nagerechten}
                alt="Meerdere pizza's"
              />
            </div>

            <h3>Prijs</h3>
            <p>
              Prijzen beginnen bij 8 euro per persoon. Wij zijn er van overtuigd
              dat deze prijs voor een toch wel origineel en, geloof ons,
              gegarandeerd culinair hoogstaand concept, bovendien nog in het
              gezellige kader van uw eigen locatie, u gemakkelijk over de streep
              zal trekken om met ons in zee te gaan.
            </p>
            <div className="text-center">
              {/* <img
                className="mb-2 mt-2"
                src="/design/img/teaser/pizza-rivierkreeften.jpg"
                alt="Meerdere pizza's"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default WaaromRitorno;
